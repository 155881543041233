<template>
  <div class="header">
    <div class="content">
      <div class="logo" @click="goroute('index')">
        <p>戴森球(北京)科技有限公司</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: undefined,
      currentsubIndex: undefined,
      isShowSubmenu: false, // 是否展示子菜单
      issubmenu: true, // 鼠标是否进入子菜单
    }
  },
  methods: {
    mouseEnter(menuindex) {
      this.currentIndex = menuindex
      if (menuindex === 1) {
        this.currentsubIndex = undefined
        this.isShowSubmenu = true
      } else {
        this.isShowSubmenu = false
      }
    },
    menuMouseleave(ix) {
      if (ix !== 1) {
        this.currentIndex = undefined
      }
    },
    subMenuEnter() {
      this.issubmenu = false
    },
    mouseLeave() {
      this.currentIndex = undefined
      this.currentsubIndex = undefined
      this.isShowSubmenu = false
      // this.issubmenu = true
    },
    // 跳转页面
    goroute(name, ix) {
      if (ix === 4) {
        this.$emit('gofoot')
      } else {
        this.$router.push({ name: name })
      }
      this.currentIndex = undefined
      this.currentsubIndex = undefined
      this.isShowSubmenu = false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../styles/global.less';
.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  margin: 0 auto;
  opacity: 0.7;
  background: #0e121a;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    .logo {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #ffffff;
      font-weight: bold;
      line-height: 56px;
      cursor: pointer;
      img {
        height: 34px;
        margin-right: 24px;
      }
    }
    .menu {
      display: flex;
      align-items: flex-start;
      line-height: 56px;
      cursor: pointer;
      .menu-item {
        position: relative;
        text-align: center;
        .item-name {
          padding: 0 20px;
          color: #ffffff;
          //&:not(:last-child) {
          //  border-right: 2px solid #ffffff;
          //}
        }
        .item-name-on {
          padding: 0 20px;
          color: #80baff;
          font-weight: 500;
          //&:not(:last-child) {
          //  border-right: 2px solid #80baff;
          //}
        }
        .item-submenu {
          position: absolute;
          top: 70px;
          left: -55%;
          width: 248px;
          opacity: 1;
          background: #070707;
          border-radius: 15px;
          backdrop-filter: blur(5px);
          .submenu-item {
            height: 40px;
            margin-bottom: 10px;
            color: #ffffff;
            line-height: 40px;
            &:is(:last-child) {
              margin-bottom: 0;
            }
          }
          .submenu-item-on {
            font-weight: 500;
            background: #0377ff;
            &:is(:first-child) {
              border-radius: 15px 15px 0 0;
            }
            &:is(:last-child) {
              border-radius: 0 0 15px 15px;
            }
          }
        }
      }
    }
  }
}
</style>
