<template>
  <div class="footer">
    <div class="content">
      <div class="copyright">
        <div>戴森球（北京）科技有限公司</div>
        <div>北京市海淀区学院南路30号楼18号院二楼</div>
        <!-- <div class="beian">
          京ICP证090496号 <img src="@/assets/image/ico_beian.png" />京公网安备<span @click="openWindow()"
            >11010802036497号</span
          > 
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    // 跳转页面
    goroute(name) {
      this.$router.push({ name: name })
    },
    // openWindow() {
    //   window.location.href = 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802036497'
    // },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../styles/global.less';
.footer {
  width: 100%;
  background: #0e121a;
  overflow: hidden;
  .content {
    width: 80%;
    margin: 48px auto 32px;

    .copyright {
      margin: 48px 0 0;
      color: #ffffff;
      > div {
        text-align: center;
      }
      span {
        margin-right: 15px;
        color: #80baff;
      }
      .beian {
        display: flex;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
