<template>
  <div class="layout">
    <the-base-header @gofoot="gofoot()"></the-base-header>
    <div>
      <router-view></router-view>
    </div>
    <the-base-footer id="footer"></the-base-footer>
  </div>
</template>

<script>
import TheBaseHeader from '../components/TheBaseHeader'
import TheBaseFooter from '../components/TheBaseFooter'
export default {
  components: { TheBaseFooter, TheBaseHeader },
  data() {
    return {}
  },
  methods: {
    gofoot() {
      document.querySelector('#footer').scrollIntoView(true)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../styles/global.less';
</style>
