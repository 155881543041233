import Vue from 'vue'
import VueRouter from 'vue-router'
import TheBaseLayout from '@/layout/TheBaseLayout'
import IndexRouter from '@/views/Index/Router.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'index',
    },
    name: 'TheBaseLayout',
    component: TheBaseLayout,
    children: [...IndexRouter],
  },
]

const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    if (to.hash) {
      if (document.querySelector(to.hash)) {
        return {
          selector: to.hash,
        }
      }
      return false
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          x: 0,
          y: 0,
        })
      }, 0)
    })
  }
}

// 解决vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

export default router
